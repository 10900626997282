import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-time-filter-dialog',
  templateUrl: './time-filter-dialog.component.html',
  styleUrls: ['./time-filter-dialog.component.css']
})
export class TimeFilterDialogComponent implements OnInit {
  selectedTabIndex = 0;
  relativeAmount: number = 1;
  relativeUnit: string = 'hour';
  startDate!: Date;
  endDate: Date = new Date(Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds()
  ));

  constructor(public dialogRef: MatDialogRef<TimeFilterDialogComponent>) {}

  ngOnInit() {
    // Retrieve stored dates from localStorage and ensure they are treated as UTC
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');
    if (storedStartDate) {
      this.startDate = new Date(storedStartDate);
    } else {
      this.startDate = new Date(this.endDate.getTime());
      this.startDate.setUTCDate(this.endDate.getUTCDate() - 1);
    }
    this.endDate = storedEndDate ? new Date(storedEndDate) : this.endDate;

    this.relativeUnit = localStorage.getItem('relativeUnit') || 'hour';
    this.relativeAmount = Number(localStorage.getItem('relativeAmount')) || 1;
  }

  save() {
    localStorage.setItem('startDate', this.startDate.toISOString());
    localStorage.setItem('endDate', this.endDate.toISOString());
    localStorage.setItem('relativeUnit', this.relativeUnit);
    localStorage.setItem('relativeAmount', String(this.relativeAmount));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getData() {
    this.save()
    if (this.selectedTabIndex === 0) {
      return {
        type: 'relative',
        relativeAmount: this.relativeAmount,
        relativeUnit: this.relativeUnit
      };
    } else {
      return {
        type: 'absolute',
        startDate: this.startDate,
        endDate: this.endDate
      };
    }
  }
}
