// app.component.ts:
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, ElementRef, ViewChild, OnInit, HostListener, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver-es';
import { BreadcrumbService } from "./breadcrumb/breadcrumb.service";
import { DynamicFlatNode, FileNode } from "./models/file-node";
import { DynamicDataSource, DynamicDatabase } from "./services/dynamic-data.service";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject, Subscription, filter, takeUntil } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { TimeFilterDialogComponent } from './time-filter-dialog/time-filter-dialog.component';
import { TimeFilterService } from './services/time-filter.service';
import { MsalBroadcastService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

/**
 * @title Tree with dynamic data
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        trigger('rotateIcon', [
            state('default', style({ transform: 'rotate(0)' })),
            state('rotated', style({ transform: 'rotate(90deg)' })),
            transition('default <=> rotated', animate('200ms ease-in'))
        ]),
        trigger('toolbarAnimation', [
            state('shown', style({
                transform: 'translateY(0)',
            })),
            state('hidden', style({
                transform: 'translateY(-100%)',
            })),
            transition('shown <=> hidden', [
                animate('0.3s')
            ]),
        ])
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    treeControl: FlatTreeControl<DynamicFlatNode>;
    dataSource: DynamicDataSource;
    @ViewChild('drawer', { read: ElementRef }) drawerRef!: ElementRef;
    selectedNode?: DynamicFlatNode = undefined;
    title?: string = "";
    getLevel = (node: DynamicFlatNode) => node.level;
    isExpandable = (node: DynamicFlatNode) => node.expandable;
    hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
    drawerWidth: number = 250;
    isInteractionInProgress: boolean = false;
    authAttempted: boolean = false;
    menuIconState = 'default';
    isMobile: boolean = window.innerWidth <= 768;
    toolbarState: string = 'hidden';
    lastScrollTop: number = 0;
    @ViewChild('sidenav') sidenav?: MatSidenav;
    isToolbarFixed: boolean = false;

    handleScroll(event: Event) {
        const target = event.target as HTMLElement;
        const { scrollTop } = target;

        if (scrollTop > this.lastScrollTop) {
            // Downscroll
            this.toolbarState = 'hidden';
            this.isToolbarFixed = true;
        } else {
            // Upscroll
            this.toolbarState = 'shown';
            this.isToolbarFixed = false;
        }
        this.lastScrollTop = scrollTop;
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth <= 768;
    }

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private database: DynamicDatabase,
        private snackBar: MatSnackBar, // Inject MatSnackBar here
        private breadcrumbService: BreadcrumbService,
        public sanitizer: DomSanitizer,
        public dialog: MatDialog,
        public timeFilterService: TimeFilterService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        this.treeControl = new FlatTreeControl<DynamicFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new DynamicDataSource(this.treeControl, database);
    }

    ngOnDestroy(): void {
        //throw new Error("Method not implemented.");
    }

    ngOnInit() {
        if (environment.supress_login) {
            // Don't authenticate on the local host
            this.fetchData();
        } else {
            this.msalBroadcastService.inProgress$
                .pipe(
                    filter((status: InteractionStatus) => status === InteractionStatus.None),
                    takeUntil(this._destroying$)
                )
                .subscribe(() => {
                    this.fetchData();
                })
        }
    }

    fetchData() {
        this.database.initialData().subscribe((node) => {
            this.dataSource.data = node;
            // Expand the first node
            if (node && node.length > 0) {
                const rootNode = node[0];
                if (rootNode && rootNode.expandable) {
                    this.treeControl.expand(rootNode);
                    if (rootNode.item.children && /[^\/]+\.\w+(?!\/)$/i.test(window.location.hash)) {
                        this.selectMatchingNode(rootNode);
                    }
                }
            }
            this.title = node[0].item.parent + node[0].item.name
            // Update breadcrumb
            this.breadcrumbService.updateTitle(this.title ?? '');
        });
    }

    async selectMatchingNode(node: DynamicFlatNode) {
        if (node.item && window.location.href.endsWith(node.item.name)) {
            this.onNodeClick(node);
            return;
        }

        if (node.expandable && node.item.children && node.item.children.length > 0) {
            this.treeControl.expand(node);
            // Define the subscription variable
            let subscription: Subscription;
            // Subscribe to dataChange to know when the node's children have been loaded
            subscription = this.dataSource.dataChange.subscribe(async () => {
                for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
                    const childNode = this.treeControl.dataNodes[i];
                    if (childNode.level === node.level + 1) {
                        await this.selectMatchingNode(childNode);
                    }
                }
                // Unsubscribe to prevent multiple subscriptions
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

    async onNodeClick(node: DynamicFlatNode) {
        this.title = node.item.parent + node.item.name;
        this.breadcrumbService.updateTitle(this.title ?? '');
        this.selectedNode = node;
        this.toolbarState = 'shown';

        if (node.item && node.item.type == "file") {
            if (!node.item.content) {
                const fileNode: FileNode | undefined =
                    await this.dataSource.requestNodeContent(node.item);
                if (fileNode) {
                    this.selectedNode.item = fileNode;
                } else {
                    this.selectedNode.item.content = undefined;
                }
            }
            if (this.isMobile && this.sidenav) {
                this.sidenav.close();
                this.toggleMenuIcon();
            }
        } else {
            this.selectedNode.item.content = undefined;
        }
    }

    onDownloadClick(content: string | undefined, fileName: string | undefined): void {
        console.log("Download button clicked");
        if (!content || !fileName) {
            this.snackBar.open("Failed to download file", "Dismiss", {
                duration: 5000,
            });
            return;
        }
        try {
            const decodedContent = atob(content.replace(/[\n\r]+/g, ''));
            const uint8Array = new Uint8Array(decodedContent.length);
            for (let i = 0; i < decodedContent.length; i++) {
                uint8Array[i] = decodedContent.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: "application/gzip" });
            saveAs(blob, fileName);
        } catch (error) {
            console.error("Error decoding base64 content", error);
            this.snackBar.open("Failed to download file", "Dismiss", {
                duration: 5000,
            });
        }
    }

    onBrandClick() {
        // Redirect to the root URL
        window.location.href = '/';
    }

    toggleMenuIcon() {
        this.menuIconState = (this.menuIconState === 'default' ? 'rotated' : 'default');
    }

    clearFilter(): void {
        this.timeFilterService.clearFilter();
        this.fetchData();
    }

    openTimeFilterDialog(): void {
        const dialogRef = this.dialog.open(TimeFilterDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // Handle the result and update filterInfo
                this.timeFilterService.setFilter(result);
                this.fetchData();
            }
        });
    }

    refreshFilter(): void {
        this.fetchData();
    }


}
