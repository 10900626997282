//dynamic-data.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, merge, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CollectionViewer, DataSource, SelectionChange } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { FileNode, DynamicFlatNode } from '../models/file-node'
import { environment } from '../../environments/environment';
import { TimeFilterService } from './time-filter.service';
import { firstValueFrom } from 'rxjs';

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({ providedIn: "root" })
export class DynamicDatabase {
    rootLevelNodes: FileNode[] = [];

    constructor(private http: HttpClient, private snackBar: MatSnackBar, private timeFilterService: TimeFilterService) { }

    /** Initial data from database */
    initialData(): Observable<DynamicFlatNode[]> {
        const bucket = environment.logs_bucket;
        let parent = "";
        let name = "";
        const { hash } = window.location;
        if (hash && hash.length > 1) {
            const parts = hash.slice(2).split("/");
            if (parts.length == 1) {
                name = parts[0];
            } else if (parts.length > 1) {
                name = parts.pop() as string;
                if (name === "") {
                    name = (parts.pop() as string) + "/";
                }
                if (parts.length >= 1) {
                    parent = parts.join("/") + "/";
                }
            }
        }
        let dates = this.timeFilterService.getDatesFromFilter();
        const timestamp = this.timeFilterService.getDateFromHashSegment(window.location.hash);
        // if there are dates, check if we have a timestamp in the hash then if the timestamp is not between the dates then we don't need to fetch the data
        if (dates && timestamp && (timestamp < dates.startDate || timestamp > dates.endDate)) {
            const emptyNode: FileNode = {
                name: "",
                type: "folder",
                parent: "",
                bucket: environment.logs_bucket,
                last_updated: undefined,
                children: []
            }
            this.rootLevelNodes = [
                emptyNode
            ];
            return of([new DynamicFlatNode(emptyNode, 0, false)]);
        }
        const payload = dates ? { name, parent, bucket, startDate: dates.startDate, endDate: dates.endDate } : { name, parent, bucket };

        return this.http.post<any>(environment.s3_lambda_endpoint, payload).pipe(
            switchMap((response) => {
                const payload = response.body || response;
                if (!payload) {
                    this.rootLevelNodes = [
                        {
                            name: "",
                            type: "folder",
                            bucket: environment.logs_bucket,
                            parent: "",
                            last_updated: undefined,
                            children: []
                        }
                    ];
                    const nodes = this.rootLevelNodes.map(
                        (node) => new DynamicFlatNode(node, 0, false)
                    );
                    return of(nodes);
                }
                // Check if payload is a string and starts with "Error:"
                if (typeof payload === 'string' && payload.startsWith('Error:')) {
                    // Display the error using MatSnackBar
                    this.snackBar.open('Failed to process request', 'Close', {
                        duration: 5000,  // Display for 5 seconds; adjust as needed
                    });

                    // Raise an error to the subscriber using the updated throwError syntax
                    return throwError(() => new Error(payload));
                } else {
                    if (!payload.name && !payload.parent) {
                        payload.name = payload.bucket;
                    }
                    this.rootLevelNodes = [payload];
                    const nodes = this.rootLevelNodes.map(
                        (node) => new DynamicFlatNode(node, 0, true)
                    );
                    return of(nodes);  // Wrap the result in an observable using "of"
                }
            }),
            catchError((error: any) => {
                // Handle the error here. For example:
                console.error("An error occurred:", error);

                const msg = error.error.match("NoSuchBucket") ? `Bucket ${environment.logs_bucket} not found` : "Failed to process request";

                // Show a snackbar notification about the error:
                this.snackBar.open(msg, 'Close', {
                    duration: 7000,  // Display for 7 seconds; adjust as needed
                });

                // You can either return a default value or re-throw the error.
                // Here's how to return a default value:
                // return of([defaultNode]);  // replace defaultNode with your default value

                // If you want to re-throw the error to the subscriber:
                return throwError(() => error);
            })
        );
    }

    async downloadFile(node: FileNode): Promise<Blob | undefined> {
        try {
            return await firstValueFrom(
                this.http.post(environment.s3_lambda_endpoint, { ...node, action: 'download' }, { responseType: 'blob' })
            );
        } catch (error) {
            console.error("Failed to download file", error);
            this.snackBar.open("Failed to download file", "Dismiss", {
                duration: 5000,
            });
            return undefined;
        }
    }

    async getChildren(node: FileNode): Promise<FileNode[] | undefined> {
        if (!node || node.type === "file") {
            return undefined;
        }
        if (!node.children || node.children.length == 0) {
            this.timeFilterService.addDatesToNode(node);
            // try to query children from remote
            try {
                const response = await firstValueFrom(this.http.post<any>(environment.s3_lambda_endpoint, node));
                if (response == undefined) {
                    return [];
                }
                const payload = response.body || response;
                let children = payload.children ?? [];
                node.children = children;
            } catch (error) {
                console.error("Failed to fetch children", error);
                this.snackBar.open("Failed to fetch children", "Dismiss", {
                    duration: 5000,
                });
                return undefined;
            }
        }
        // sort the nodes with timestamps based on last_updated property 
        if (node.children) {
            node.children = node.children.sort((a, b) => {
                const aName = a.name.slice(0, -1);
                const bName = b.name.slice(0, -1);
                if (!isNaN(Number(aName)) && !isNaN(Number(bName))) {
                    return Number(bName) - Number(aName);
                }
                return 0;
            });
        }
        return node.children;
    }

    isExpandable(node: FileNode): boolean {
        return node.type === "folder";
    }

    async requestNodeContent(node: FileNode): Promise<any> {
        try {
            const response = await firstValueFrom(this.http.post<any>(environment.s3_lambda_endpoint, node));
            const payload = response.body || response;
    
            if (!payload) {
                return this.handleError("No content available");
            }
    
            if (payload.type === "folder" && payload.children) {
                for (const child of payload.children) {
                    if (child.name === node.name) {
                        child.display_name = node.display_name;
                        return child;
                    }
                }
            } else if (payload.type === "file") {
                node.content = payload.content;
                node.is_binary = !!payload.is_binary;
                return node;
            } else {
                return this.handleError("No content available");
            }
        } catch (error) {
            return this.handleError("Failed to fetch file content", error);
        }
    }
    
    private handleError(message: string, error?: any): undefined {
        console.error(message, error);
        this.snackBar.open(message, "Dismiss", {
            duration: 5000,
        });
        return undefined;
    }
    
}

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
    dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

    get data(): DynamicFlatNode[] {
        return this.dataChange.value;
    }
    set data(value: DynamicFlatNode[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }
    constructor(
        private _treeControl: FlatTreeControl<DynamicFlatNode>,
        private _database: DynamicDatabase
    ) { }

    connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
        this._treeControl.expansionModel.changed.subscribe((change) => {
            if (
                (change as SelectionChange<DynamicFlatNode>).added ||
                (change as SelectionChange<DynamicFlatNode>).removed
            ) {
                this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(
            map(() => this.data)
        );
    }

    disconnect(collectionViewer: CollectionViewer): void { }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
        if (change.added) {
            change.added.forEach((node) => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed
                .slice()
                .reverse()
                .forEach((node) => this.toggleNode(node, false));
        }
    }

    /**
     * Toggle the node, remove from display list
     */
    async toggleNode(node: DynamicFlatNode, expand: boolean) {
        const children = await this._database.getChildren(node.item);
        const index = this.data.indexOf(node);
        if (!children || index < 0) {
            // If no children, or cannot find the node, no op
            return;
        }
        node.isLoading = true;

        setTimeout(() => {
            if (expand) {
                const nodes = children.map(
                    (name) =>
                        new DynamicFlatNode(
                            name,
                            node.level + 1,
                            this._database.isExpandable(name)
                        )
                );
                this.data.splice(index + 1, 0, ...nodes);
            } else {
                let count = 0;
                for (
                    let i = index + 1;
                    i < this.data.length && this.data[i].level > node.level;
                    i++, count++
                ) { }
                this.data.splice(index + 1, count);
            }

            // notify the change
            this.dataChange.next(this.data);
            node.isLoading = false;
        }, 100);
    }

    async requestNodeContent(node: FileNode): Promise<FileNode | undefined> {
        return await this._database.requestNodeContent(node);
    }
}
