import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2,
  AfterViewInit
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.css']
})
export class HtmlViewerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() htmlContent!: string;
  safeHtmlContent!: SafeHtml;
  private observer!: MutationObserver;
  private currentLineWrapper: { value: number } = { value: 0 };

  constructor(
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.updateSafeHtmlContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['htmlContent'] && !changes['htmlContent'].firstChange) {
      this.updateSafeHtmlContent();
      // Give Angular some time to update the DOM before binding the new event listeners.
      setTimeout(() => {
        this.bindNextPrevButtons();
        this.bindScrollToTopButton();
      }, 0);
    }
  }

  ngAfterViewInit(): void {
    this.initMutationObserver();
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private updateSafeHtmlContent(): void {
    if (this.htmlContent) {
      // Remove the inline onscroll attribute
      this.htmlContent = this.htmlContent.replace(
        'onscroll="OnScroll(this)"',
        ''
      );
      // Remove the inline onclick attribute and add an id attribute
      this.htmlContent = this.htmlContent.replace(
        'onclick="scrollToTop()"',
        'id="toTop"'
      );
      this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
        this.htmlContent
      );
      this.bindScrollToTopButton();
      // remove <script> tags
      const scriptTags = this.el.nativeElement.querySelectorAll('script');
      scriptTags.forEach((script: any) => {
        this.renderer.removeChild(this.el.nativeElement, script);
      });
    }
  }

  private initMutationObserver(): void {
    this.observer = new MutationObserver(() => {
      this.bindOnScrollFunction();
      this.bindNextPrevButtons();
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true
    });
  }

  private bindOnScrollFunction(): void {
    const controlFileDiv = this.el.nativeElement.querySelector('#controlfile');

    if (controlFileDiv) {
      this.renderer.listen(controlFileDiv, 'scroll', (event: Event) =>
        this.onScroll(event.target)
      );
      this.observer.disconnect();
    }
  }

  private bindNextPrevButtons(): void {
    const nextBtn = this.el.nativeElement.querySelector('#next');
    const prevBtn = this.el.nativeElement.querySelector('#prev');
    const scriptTags = this.el.nativeElement.querySelectorAll('script');

    for (let i = 0; i < scriptTags.length; i++) {
      const scriptContent = scriptTags[i].innerText;
      this.bindFunctionsToButtons(nextBtn, prevBtn, scriptContent);
    }
  }

  private bindFunctionsToButtons(nextBtn: HTMLElement, prevBtn: HTMLElement, scriptContent: string): void {
    const buttonFunctions = [
      {
        button: nextBtn,
        regex: /nextBtn\.addEventListener\("click",\s*function\s*\(\)\s*{([\s\S]+?})\s*}\);/,
      },
      {
        button: prevBtn,
        regex: /prevBtn\.addEventListener\("click",\s*function\s*\(\)\s*{([\s\S]+?})\s*}\);/,
      },
    ];
  
    buttonFunctions.forEach(({ button, regex }) => {
      const functionMatch = scriptContent.match(regex);
  
      if (functionMatch) {
        let functionBody = functionMatch[1];
        functionBody = functionBody.replace(/currentLine/g, "currentLineWrapper.value");
        if (button === nextBtn) {
          functionBody = functionBody.replace(
            "lineElements[currentLine].scrollIntoView({ block: 'start', behavior: 'instant' });",
            `if (currentLineWrapper.value < lineElements.length - 1) {
              lineElements[currentLineWrapper.value + 1].scrollIntoView({ block: 'start', behavior: 'instant' });
              const resultFileDiv = document.getElementById('resultfile');
              if (resultFileDiv) {
                const resultFileLineElements = resultFileDiv.getElementsByClassName('highlight');
                if (resultFileLineElements && resultFileLineElements[currentLineWrapper.value + 1]) {
                  resultFileLineElements[currentLineWrapper.value + 1].scrollIntoView({ block: 'start', behavior: 'instant' });
                }
              }
              currentLineWrapper.value++;
            }`
          );
        }
        const boundFunction = this.createBoundFunction(button, functionBody);
        this.renderer.listen(button, 'click', boundFunction);
      }
    });
  }
    

  private createBoundFunction(
    button: HTMLElement,
    functionBody: string
  ): (event: any) => void {
    const func = new Function(
      "button",
      "lineElements",
      "currentLineWrapper",
      "resultFileDiv",
      `
      return (function() {
        if (lineElements[currentLineWrapper.value]) {
          {${functionBody}}
          if (resultFileDiv && currentLineWrapper.value < lineElements.length && currentLineWrapper.value >= 0) {
            console.log("current line: " + currentLineWrapper.value);
            //debugger;
            resultFileDiv.scrollTop = lineElements[currentLineWrapper.value].offsetTop;
          }
        }
      });
    `
    );
    const resultFileDiv = this.el.nativeElement.querySelector('#resultfile');
    const generatedFunction = func(button, this.getLineElements(), this.currentLineWrapper, resultFileDiv);
    return (event: any) => {
      generatedFunction();
    };
  }

  private getLineElements(): HTMLElement[] {
    const controlFileDiv = this.el.nativeElement.querySelector('#controlfile');
    return Array.from(controlFileDiv.getElementsByClassName('highlight'));
  }


  onScroll(div: any): void {
    const resultFileDiv = document.getElementById('resultfile');
    if (resultFileDiv) {
      resultFileDiv.scrollTop = div.scrollTop;
    }
  }
  
  private bindScrollToTopButton(): void {
    const scrollToTopBtn = this.el.nativeElement.querySelector('#toTop');
  
    if (scrollToTopBtn) {
      this.renderer.listen(scrollToTopBtn, 'click', () => {
        const controlFileDiv = this.el.nativeElement.querySelector('#controlfile');
        const resultFileDiv = this.el.nativeElement.querySelector('#resultfile');
  
        controlFileDiv.scrollTo({ top: 0, behavior: 'smooth' });
        if (resultFileDiv) {
          resultFileDiv.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    }
  }  
}
