export const environment = {
    clientId: 'e75b87de-88ff-415e-92ce-f129af1a0419', // your client ID
    authority: 'https://login.microsoftonline.com/9151cbaa-fc6b-4f48-89bb-8c4a71982138', // your issuer URL
    //s3_lambda_endpoint: 'https://ezz7ixd5m1.execute-api.us-west-2.amazonaws.com/test/mario-lambda-tce8415c', // file browser URL
    //logs_bucket: 'skyjack-results-tce8415c',
    //s3_lambda_endpoint: 'https://p38sj6ke79.execute-api.us-west-2.amazonaws.com/default/mario-lambda', // file browser URL
    //logs_bucket: 'skyjack-results-tcd28808',
    s3_lambda_endpoint: 'https://ey9rqikio4.execute-api.us-west-2.amazonaws.com/default/mario-lambda-tc268cb7', // file browser URL
    logs_bucket: 'skyjack-results-tc268cb7',
    supress_login: true
}