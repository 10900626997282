import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { Subscription } from 'rxjs';
import { fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy, AfterViewInit {
  title?: string;
  titleSubscription?: Subscription;
  breadcrumbItems: any[] = [];
  base_url: string = ''; // Add this line to set the base_url property
  @Input() containerWidth: number | null = null;
  @ViewChild('breadcrumbNav', { static: false }) breadcrumbNav?: ElementRef<HTMLElement>;
  private observer!: MutationObserver;
  @Input() isMobile?: boolean;
  selectedValue?: string;

  constructor(private breadcrumbService: BreadcrumbService, private elementRef: ElementRef) { }

  navigateTo(route: string) {
    window.location.href = window.location.origin + '/#/' + route;
    location.reload();
  }

  navigateToDropdown(url: string) {
    if (url !== this.selectedValue) {
      this.navigateTo(url);
    }
  }

  ngOnInit(): void {
    this.titleSubscription = this.breadcrumbService.currentTitle.subscribe(
      (title: string) => {
        this.title = title;
        this.updateBreadcrumb();
        setTimeout(() => {
          this.measureBreadcrumbItems();
          this.adjustBreadcrumbItems();
        }, 50);
      }
    );
  }

  ngAfterViewInit(): void {
    if (this.breadcrumbNav) {
      const resize$ = fromEvent(window, 'resize').pipe(debounceTime(150));
      const navWidth$ = fromEvent(this.breadcrumbNav.nativeElement, 'widthChanged');
      merge(resize$, navWidth$).subscribe(() => {
        this.adjustBreadcrumbItems();
      });
    }
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
    }
  }

  /**
   * Updates the breadcrumb items based on the current title.
   */
  private updateBreadcrumb(): void {
    if (this.title) {
      const parts = this.title.split('/').filter(part => part.length > 0).map((part, index, array) => {
        if (index === array.length - 1) {
          return part;
        } else {
          return part + '/';
        }
      });
      this.breadcrumbItems = [];
      let currentPath = '';

      for (const part of parts) {
        currentPath += part;
        this.breadcrumbItems.push({ label: part, url: currentPath, width: 0, originalLabel: part, collapsed: false });
      }
      // Set the selected value to the URL of the last breadcrumb item
      if (this.breadcrumbItems.length > 0) {
        this.selectedValue = this.breadcrumbItems[this.breadcrumbItems.length - 1].label;
      }
    } else {
      this.breadcrumbItems = [];
    }
  }

  /**
   * Measures the breadcrumb items and stores the width in the breadcrumbItems array.
   */
  private measureBreadcrumbItems(): void {
    const breadcrumbItems = this.elementRef.nativeElement.querySelectorAll('.breadcrumb-item'); console.log(breadcrumbItems);

    breadcrumbItems.forEach((item: HTMLElement, index: number) => {
      const style = getComputedStyle(item);

      const marginLeft = parseFloat(style.marginLeft) || 0;
      const marginRight = parseFloat(style.marginRight) || 0;
      const paddingLeft = parseFloat(style.paddingLeft) || 0;
      const paddingRight = parseFloat(style.paddingRight) || 0;

      const width = item.getBoundingClientRect().width + marginLeft + marginRight + paddingLeft + paddingRight;
      this.breadcrumbItems[index].width = width;
    });
  }

  /**
   * Adjusts the breadcrumb items by collapsing them if necessary.
   */
  private adjustBreadcrumbItems(): void {
    const maxWidth = this.breadcrumbNav?.nativeElement.clientWidth;
    if (maxWidth && this.breadcrumbItems) {
      let totalWidth = 0;

      for (const item of this.breadcrumbItems) {
        totalWidth += item.width;
      }

      for (const item of this.breadcrumbItems.slice(1, this.breadcrumbItems.length - 1)) {
        if (totalWidth > maxWidth && item.collapsed === false) {
          item.label = '...';
          item.collapsed = true;
          totalWidth -= item.width + 35;
        }
      }
    }
  }
}
