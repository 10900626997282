<!-- For larger screens -->
<ng-container *ngIf="!isMobile; else mobileTemplate">
  <ng-container *ngFor="let item of breadcrumbItems; let last = last">
    <a [href]="'#/' + item.url" class="breadcrumb-item breadcrumb-link" [title]="item.collapsed ? item.originalLabel : ''" (click)="navigateTo(item.url)">{{ item.label }}</a>
  </ng-container>
</ng-container>

<!-- For smaller screens -->
<ng-template #mobileTemplate>
  <button mat-button [matMenuTriggerFor]="breadcrumbMenu" class="breadcrumb-button">{{ selectedValue }}</button>
  <mat-menu #breadcrumbMenu="matMenu">
    <button mat-menu-item *ngFor="let item of breadcrumbItems" (click)="navigateToDropdown(item.url)">{{ item.label }}</button>
  </mat-menu>
</ng-template>
