<h2 mat-dialog-title>Filter by date and time</h2>
<mat-dialog-content>
  <mat-tab-group [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="Relative">
      <!-- Relative Tab -->
      <div class="relative-tab">
        <mat-form-field appearance="fill" class="amount-field">
          <mat-label>Amount</mat-label>
          <input matInput type="number" [(ngModel)]="relativeAmount" name="relativeAmount" min="1">
        </mat-form-field>

        <mat-form-field appearance="fill" class="unit-field">
          <mat-label>Unit</mat-label>
          <mat-select [(ngModel)]="relativeUnit" name="relativeUnit">
            <mat-option value="minute">Minutes</mat-option>
            <mat-option value="hour">Hours</mat-option>
            <mat-option value="day">Days</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-tab>

    <mat-tab label="Absolute">
      <!-- Absolute Tab -->
      <div class="absolute-tab">
        <mat-form-field appearance="fill">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatetimepicker]="startPicker" [(ngModel)]="startDate" name="startDate">
          <mat-datetimepicker-toggle matSuffix [for]="startPicker"></mat-datetimepicker-toggle>
          <mat-datetimepicker #startPicker type="datetime"></mat-datetimepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>End Date</mat-label>
          <input matInput [matDatetimepicker]="endPicker" [(ngModel)]="endDate" name="endDate">
          <mat-datetimepicker-toggle matSuffix [for]="endPicker"></mat-datetimepicker-toggle>
          <mat-datetimepicker #endPicker type="datetime"></mat-datetimepicker>
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="getData()" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
