import { Injectable } from '@angular/core';
import { FileNode } from '../models/file-node';

@Injectable({
  providedIn: 'root',
})
export class TimeFilterService {
  private filter: { type: 'relative' | 'absolute', startDate?: Date, endDate?: Date, relativeAmount?: number, relativeUnit?: string } | null = null;

  constructor() {
    const fltr = localStorage.getItem('timeFilter');
    if (fltr !== null) {
      this.filter = JSON.parse(fltr);
      // Convert startDate and endDate strings to Date objects
      if (this.filter !== null) {
        if (this.filter.startDate) {
          this.filter.startDate = new Date(this.filter.startDate);
        }
        if (this.filter.endDate) {
          this.filter.endDate = new Date(this.filter.endDate);
        }
      }
    }
  }

  //return this.filter in the getter

  getFilter(): { type: 'relative' | 'absolute', startDate?: Date, endDate?: Date, relativeAmount?: number, relativeUnit?: string } | null {
    return this.filter;
  }

  setFilter(filter: { type: 'relative' | 'absolute', startDate?: Date, endDate?: Date, relativeAmount?: number, relativeUnit?: string }) {
    this.filter = filter;
    localStorage.setItem('timeFilter', JSON.stringify(filter));
  }

  clearFilter() {
    this.filter = null;
    localStorage.removeItem('timeFilter');
  }

  getDatesFromFilter(): { startDate: Date, endDate: Date } | null {
    if (!this.filter) {
      return null;
    }

    let startDate: Date;
    let endDate: Date;

    if (this.filter.type === 'relative' && this.filter.relativeAmount !== undefined && this.filter.relativeUnit !== undefined) {
      startDate = this.computeStartDate(this.filter.relativeAmount, this.filter.relativeUnit);
      endDate = new Date();
    } else if (this.filter.type === 'absolute' && this.filter.startDate && this.filter.endDate) {
      startDate = this.filter.startDate;
      endDate = this.filter.endDate;
    } else {
      throw new Error('Invalid filter type or missing properties');
    }

    return { startDate, endDate };
  }

  addDatesToNode(node: FileNode) {
    if (this.filter === null || !node) {
      return;
    }
    const dates = this.getDatesFromFilter();
    if (dates) {
      node.startDate = dates.startDate;
      node.endDate = dates.endDate;
    }
  }

  generateFilterInfo(): string {
    if (!this.filter) {
      throw new Error('Time filter is not set');
    }

    if (this.filter.type === 'relative' && this.filter.relativeAmount !== undefined && this.filter.relativeUnit !== undefined) {
      return "Last " + this.filter.relativeAmount + " " + this.filter.relativeUnit + "(s)";
    } else if (this.filter.type === 'absolute' && this.filter.startDate && this.filter.endDate) {
      return `${this.formatDate(this.filter.startDate)} to ${this.formatDate(this.filter.endDate)}`;
    } else {
      throw new Error('Invalid filter type or missing properties');
    }
  }

  private computeStartDate(relativeAmount: number, relativeUnit: string): Date {
    const startDate = new Date(Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds()
    ));

    switch (relativeUnit) {
      case 'minute':
        startDate.setUTCMinutes(startDate.getUTCMinutes() - relativeAmount);
        break;
      case 'hour':
        startDate.setUTCHours(startDate.getUTCHours() - relativeAmount);
        break;
      case 'day':
        startDate.setUTCDate(startDate.getUTCDate() - relativeAmount);
        break;
      default:
        console.error('Invalid unit type');
        break;
    }

    return startDate;
  }

  private formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC'
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  public getDateFromHashSegment(hash: string): Date | null {
    const hashSegments = hash.slice(1).split("/");
    if (hashSegments.length > 2) {
      const timestampSegment = hashSegments[2];
      const regex = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{6})$/;
      const matches = timestampSegment.match(regex);
      if (matches) {
        const year = +matches[1];
        const month = +matches[2] - 1; // Months are 0-based in JS Date object
        const day = +matches[3];
        const hour = +matches[4];
        const minute = +matches[5];
        const second = +matches[6];
        const millisecond = +matches[7].slice(0, 3); // Taking only the first 3 digits for milliseconds
  
        return new Date(Date.UTC(year, month, day, hour, minute, second, millisecond));
      }
    }
    return null;
  }
}
