<mat-toolbar color="primary">
  <button mat-icon-button (click)="sidenav.toggle(); toggleMenuIcon()">
    <div [@rotateIcon]="menuIconState">
      <mat-icon>menu</mat-icon>
    </div>
  </button>
  <a class="mario-branding" (click)="onBrandClick()">
    <span class="mario-text">Mario</span>
  </a>
  <span class="spacer"></span>
  <div class="filter-info-container" *ngIf="timeFilterService.getFilter()">
    <div class="toolbar-separator"></div>
    <mat-label class="filter-info-text">
      {{ timeFilterService.generateFilterInfo() }}
    </mat-label>
    <button mat-icon-button (click)="refreshFilter()" class="refresh-filter-button">
      <mat-icon>refresh</mat-icon>
    </button>
    <button mat-icon-button (click)="clearFilter()" class="clear-filter-button">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="toolbar-separator"></div>
  </div>
  <button mat-icon-button aria-label="By datetime" (click)="openTimeFilterDialog()">
    <mat-icon>date_range</mat-icon>
  </button>
  <!-- <button mat-icon-button aria-label="Search">
    <mat-icon>search</mat-icon>
  </button> -->
</mat-toolbar>

<div class="app-container">
  <mat-sidenav-container class="mario-container">
    <mat-sidenav #sidenav class="mario-sidenav" mode="side" opened>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [class.selected]="node === selectedNode"
          (click)="onNodeClick(node)" matTreeNodePaddingIndent="6pt">
          <button mat-icon-button disabled></button>

          <span *ngIf="!node.item.display_name || node.item.display_name === ''; else displayItemName"
            [ngStyle]="{'color': 'red'}">No data</span>
          <ng-template #displayItemName>
            {{ node.item.display_name || node.item.bucket }}
          </ng-template>

        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
          [class.selected]="node === selectedNode" (click)="onNodeClick(node)" matTreeNodePaddingIndent="6pt">
          <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item.display_name" matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>

          <span *ngIf="!node.item.display_name || node.item.display_name === ''; else displayItemNameChild"
            [ngStyle]="{'color': 'red'}">undefined</span>
          <ng-template #displayItemNameChild>
            {{ node.item.display_name || node.item.bucket }}
          </ng-template>

          <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate"
            class="mario-tree-progress-bar"></mat-progress-bar>
        </mat-tree-node>
      </mat-tree>

    </mat-sidenav>
    <mat-sidenav-content resizable class="mario-content">
      <div class="content-wrapper">
        <!-- Secondary Toolbar -->
        <div class="toolbar-wrapper" [@toolbarAnimation]="toolbarState">
          <mat-toolbar class="secondary-toolbar">
            <app-breadcrumb [title]="title" [isMobile]="isMobile"></app-breadcrumb>
            <span class="spacer"></span>
          </mat-toolbar>
        </div>
        <div class="scrollable-content" [class.fixed-toolbar]="isToolbarFixed"
          [ngStyle]="{'height': toolbarState === 'shown' ? (isMobile ? 'calc(100vh - 56px - 64px)' : 'calc(100vh - 64px - 64px)') : 'calc(100vh - 64px)'}"
          (scroll)="handleScroll($event)">
          <ng-container *ngIf="selectedNode && selectedNode.item.content !== undefined; else noContentMessage">
            <ng-container *ngIf="selectedNode && selectedNode.item.content === ''; else nonEmptyContent">
              <div class="empty-file-message">
                <span style="color: red;">The file is empty.</span>
              </div>
            </ng-container>
            <ng-template #nonEmptyContent>
              <ng-container *ngIf="selectedNode?.item?.is_binary; else textualContent">
                <div class="code-pane">Binary file content cannot be displayed.</div>
                <div class="download-button-container">
                  <button *ngIf="selectedNode && selectedNode.item.content && selectedNode.item.name" mat-raised-button
                    color="primary"
                    (click)="onDownloadClick(selectedNode.item.content, selectedNode.item.name)">Download</button>
                </div>
              </ng-container>
              <ng-template #textualContent>
                <ng-container *ngIf="selectedNode?.item?.name?.endsWith('.html'); else plainTextContent">
                  <!-- If the selected node has an .html extension, display the content as HTML -->
                  <app-html-viewer [htmlContent]="selectedNode.item.content"></app-html-viewer>
                </ng-container>
                <ng-template #plainTextContent>
                  <!-- If the selected node is a textual file, display the content as text -->
                  <div class="code-pane">
                    <pre><code>{{selectedNode.item.content}}</code></pre>
                  </div>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-template #noContentMessage>
            <div class="no-content-wrapper">
              <img src="assets/images/svg/logo.svg" class="mario-icon" alt="Mario">
            </div>
          </ng-template>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>