// file-node.model.ts
export interface FileNode {
    name: string;
    display_name?: string;
    type?: "folder" | "file";
    parent: string;
    bucket?: string;
    children?: FileNode[];
    content?: string;
    last_updated?: string;
    is_binary?: boolean;
    startDate?: Date;
    endDate?: Date;
}

export class DynamicFlatNode {
    constructor(
        public item: FileNode,
        public level = 1,
        public expandable = false,
        public isLoading = false
    ) {
        // update name property when node is expanded
        if (this.item) {
            if (this.expandable && this.item.name.includes('/')) {
                const base = this.item.name.slice(0, -1);
                if (!isNaN(Number(base))) {
                    const formattedDate = `${base.slice(0, 4)}-${base.slice(4, 6)}-${base.slice(6, 8)} ${base.slice(8, 10)}:${base.slice(10, 12)}:${base.slice(12, 14)}`;
                    this.item.display_name = `${formattedDate}`;
                    return
                }
            }
            this.item.display_name = this.item.name;
        } 
    }
}
